function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { WORKOS_IRIS_THEME_ID } from "segments/segments-service";
import { APPS_MARKETPLACE_LINK, AUTOMATIONS_PAGE_LINK, DASHBOARDS_PAGE_LINK, INTEGRATIONS_PAGE_LINK, WORKDOCS_PAGE_LINK } from "constants/links";
import TwoThirdsTextAndAssetComponentDefaultProps from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/default-props";
const _TwoThirdsTextAndAssetComponentDefaultProps_asset = TwoThirdsTextAndAssetComponentDefaultProps.asset, { image } = _TwoThirdsTextAndAssetComponentDefaultProps_asset, selectedAssetComponentDefaultProps = _object_without_properties(_TwoThirdsTextAndAssetComponentDefaultProps_asset, [
    "image"
]);
export default {
    buildingBlocksTabsTheme: WORKOS_IRIS_THEME_ID,
    tabs: [
        {
            tabLabel: "Boards",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/board_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Boards",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    body: "Everything starts with a visual board — the core of monday.com Work OS. Tailor it your way and manage anything from projects to departments."
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/7_status.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        },
        {
            tabLabel: "Views",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/views_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Views",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    body: "Visualize and plan your work more efficiently with multiple views: Kanban board, calendar, timeline, Gantt chart, and more."
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/1_Views.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        },
        {
            tabLabel: "Dashboards",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/dashboard_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Dashboards",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    buttonConfig: {
                        buttonType: "Secondary",
                        buttonText: "Learn more",
                        url: DASHBOARDS_PAGE_LINK
                    },
                    body: "Get the insights you need to make decisions with confidence. Keep track of progress, timelines, and budgets with custom dashboards."
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/2_Dashboards.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        },
        {
            tabLabel: "Integrations",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/integrations_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Integrations",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    buttonConfig: {
                        buttonType: "Secondary",
                        buttonText: "Learn more",
                        url: INTEGRATIONS_PAGE_LINK
                    },
                    body: "Connect monday.com with all your favorite tools and get more work done. Integrate Slack, Dropbox, Adobe Creative Cloud, and more."
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/4_Integrations.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        },
        {
            tabLabel: "Automations",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/automations_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Automations",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    buttonConfig: {
                        buttonType: "Secondary",
                        buttonText: "Learn more",
                        url: AUTOMATIONS_PAGE_LINK
                    },
                    body: "Streamline processes to focus on the work that matters. Choose from a variety of automation recipes or create your own in minutes."
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/3_Automations.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        },
        {
            tabLabel: "Apps",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/apps_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Apps",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    buttonConfig: {
                        buttonType: "Secondary",
                        buttonText: "Learn more",
                        url: APPS_MARKETPLACE_LINK
                    },
                    body: "Expand the capabilities of your Work OS with monday apps. Enhance your workflows with custom views, widgets, integrations, and more. "
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/5_Apps.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        },
        {
            tabLabel: "Docs",
            tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/docs_icon.svg`,
            textAndAsset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                swapTextAndAssetPosition: true,
                fullParagraph: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph), {
                    topic: {},
                    title: "Docs",
                    titleColor: WORK_OS_IRIS_COLOR_NAME,
                    buttonConfig: {
                        buttonType: "Secondary",
                        buttonText: "Learn more",
                        url: WORKDOCS_PAGE_LINK
                    },
                    body: "Transform text into action items, in just a few clicks. Connect, collaborate, and execute ideas and workflows in real-time from any doc."
                }),
                asset: _object_spread_props(_object_spread({}, selectedAssetComponentDefaultProps), {
                    video: {
                        loop: true,
                        src: {
                            formats: [
                                "/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/6_Docs.mp4"
                            ]
                        }
                    },
                    position: "center",
                    useWindowMask: true
                })
            })
        }
    ]
};
