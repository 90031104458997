import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledBuildingBlocksTabsDesktopComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  .building-blocks-tabs-wrapper {
    height: 83px;
    box-shadow: 0px 10px 30px rgba(29, 140, 242, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    font-size: 0.875rem;
  }

  .building-blocks-contents-wrapper {
    width: 100%;
    position: relative;
  }
`;
