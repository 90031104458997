import styled from "@emotion/styled";
import colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
export const StyledBuildingBlocksTabComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  cursor: pointer;
  padding: 0 20px;
  align-items: center;
  min-width: 100px;
  user-select: none;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 8px;
    opacity: 0.6;
    position: relative;
    .icon-absolute-container {
      position: absolute;
      top: 0;
      opacity: 0;
      &.visible {
        opacity: 1;
      }
    }
  }

  .name {
    font-weight: 300;
    opacity: 0.6;
  }

  .marker {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: background-color 0.1s ease-in-out;
  }
  &.selected {
    cursor: pointer;
    .icon-wrapper {
      opacity: 1;
    }
    .name {
      opacity: 1;
    }
  }
  &:hover {
    background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
  }
`;
